import "./App.css";
import {useState} from "react";
import {Button, Card, Input, Typography,} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";

function PrivacyPolicy() {
    const navigate = useNavigate();

    const googleLogin = () => {
        window.location.href =
            process.env.REACT_APP_API_URL + "/oauth2/authorization/google";
    };

    const [loginError, setloginError] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        formData.append("username", formData.get("username"));
        formData.append("password", formData.get("password"));

        fetch(process.env.REACT_APP_API_URL + "/login", {
            method: "POST",
            body: formData,
            crossDomain: true,
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        setloginError("Username or password incorrect");
                    }
                } else {
                    const expires = new Date();
                    expires.setTime(expires.getTime() + 20 * 60 * 1100);
                    //document.cookie = `Authorization=;expires=${expires.toUTCString()};path=/`;
                    const authorization = response.headers
                        .get("Authorization")
                        .split(" ")[1];
                    document.cookie = `Authorization=${authorization};expires=${expires.toUTCString()};path=/`;
                    navigate("/services");
                }
            })

            .catch((err) => err);
    };
    return (
        <>
            <div className="min-h-screen bg-gray-100 py-8 px-4">
                <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
                    <h1 className="text-3xl font-semibold text-center text-gray-800 mb-6">
                        Privacy Policy
                    </h1>

                    <p className="text-sm text-gray-500 text-center mb-6">
                        <strong>Effective Date:</strong> 2024-05-01
                    </p>

                    <p className="text-lg text-gray-700 mb-6">
                        This Privacy Policy outlines how we collect, use, and protect your
                        personal information when you use our website and services. By using
                        our site, you consent to the practices described in this policy. If you
                        do not agree with this policy, please refrain from using our website.
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-3">
                        1. Information We Collect
                    </h2>
                    <p className="text-lg text-gray-700 mb-4">
                        We collect personal information when you sign up or log in using Google
                        social login, including but not limited to:
                    </p>
                    <ul className="list-disc pl-6 text-lg text-gray-700 mb-4">
                        <li>Your email address</li>
                        <li>Profile information (e.g., name, profile picture)</li>
                        <li>Other information provided by Google as part of the login process</li>
                    </ul>
                    <p className="text-lg text-gray-700 mb-6">
                        We may also collect non-personal information through cookies and other
                        tracking technologies to improve the functionality of the site.
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-3">
                        2. How We Use Your Information
                    </h2>
                    <p className="text-lg text-gray-700 mb-4">
                        We use the collected personal information to:
                    </p>
                    <ul className="list-disc pl-6 text-lg text-gray-700 mb-4">
                        <li>Create and manage your account</li>
                        <li>Provide services related to your account, such as booking or reservation management</li>
                        <li>Communicate with you regarding updates, offers, or other relevant information related to our services</li>
                        <li>Improve the user experience on our website</li>
                    </ul>
                    <p className="text-lg text-gray-700 mb-6">
                        We will not use your personal information for purposes beyond those
                        specified in this Privacy Policy unless you provide explicit consent.
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-3">
                        3. Information Sharing
                    </h2>
                    <p className="text-lg text-gray-700 mb-4">
                        We will not sell, trade, or rent your personal information to third
                        parties. However, we may share your information with trusted third
                        parties under the following circumstances:
                    </p>
                    <ul className="list-disc pl-6 text-lg text-gray-700 mb-4">
                        <li>To comply with legal obligations or respond to legal requests</li>
                        <li>To protect our rights or the rights of others</li>
                        <li>With third-party service providers who assist us in operating the website or conducting business (e.g., payment processors), but only to the extent necessary for them to provide their services</li>
                    </ul>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-3">
                        4. Cookies and Tracking Technologies
                    </h2>
                    <p className="text-lg text-gray-700 mb-4">
                        We use cookies and other tracking technologies to enhance your
                        experience on our website. Cookies help us remember your preferences,
                        analyze site traffic, and improve functionality. You can control cookie
                        settings through your browser, but please note that disabling cookies
                        may affect certain features of the website.
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-3">
                        5. Data Security
                    </h2>
                    <p className="text-lg text-gray-700 mb-4">
                        We take reasonable precautions to protect your personal information
                        from unauthorized access, alteration, or destruction. However, please
                        note that no data transmission or storage method is 100% secure, and
                        we cannot guarantee the absolute security of your data.
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-3">
                        6. Your Rights
                    </h2>
                    <p className="text-lg text-gray-700 mb-4">
                        Under Canadian privacy law (PIPEDA), you have the right to:
                    </p>
                    <ul className="list-disc pl-6 text-lg text-gray-700 mb-4">
                        <li>Access the personal information we hold about you</li>
                        <li>Request correction or deletion of your personal information</li>
                        <li>Withdraw your consent to the collection or processing of your personal information at any time (subject to legal or contractual restrictions)</li>
                    </ul>
                    <p className="text-lg text-gray-700 mb-6">
                        To exercise these rights or if you have any questions about your
                        personal information, please contact us at 604 937 3969.
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-3">
                        7. Changes to this Privacy Policy
                    </h2>
                    <p className="text-lg text-gray-700 mb-4">
                        We may update this Privacy Policy from time to time to reflect changes
                        in our practices or legal requirements. Any updates will be posted on
                        this page with a revised "Effective Date." Please review this policy
                        periodically to stay informed about how we are protecting your personal
                        information.
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-3">
                        8. Contact Us
                    </h2>
                    <p className="text-lg text-gray-700 mb-4">
                        If you have any questions or concerns about this Privacy Policy or how
                        we handle your personal information, please contact us at:
                    </p>
                    <p className="text-lg text-gray-700 mb-4">
                        <strong>No1. Jimmy Auto</strong><br />
                        <strong>Email:</strong> help@no1jimmyauto.com <br />
                        <strong>Phone:</strong> 604 937 3969
                    </p>
                </div>
            </div>
        </>
    );
}

export default PrivacyPolicy;
